var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('ValidationObserver',{ref:"createReminderEventValidation"},[_c('b-card-code',[_c('h3',{staticClass:"mb-2"},[_vm._v(" Create reminder events ")]),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name"}},[_c('b-form-input',{attrs:{"placeholder":"Name"},model:{value:(_vm.reminderEvent.name),callback:function ($$v) {_vm.$set(_vm.reminderEvent, "name", $$v)},expression:"reminderEvent.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"English name"}},[_c('b-form-input',{attrs:{"placeholder":"English name"},model:{value:(_vm.reminderEvent.en_name),callback:function ($$v) {_vm.$set(_vm.reminderEvent, "en_name", $$v)},expression:"reminderEvent.en_name"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Is Solar"}},[_c('v-select',{attrs:{"options":_vm.isSolar,"clearable":false,"label":"name"},model:{value:(_vm.reminderEvent.is_solar),callback:function ($$v) {_vm.$set(_vm.reminderEvent, "is_solar", $$v)},expression:"reminderEvent.is_solar"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Type"}},[_c('v-select',{attrs:{"options":_vm.types,"clearable":false,"label":"name"},model:{value:(_vm.reminderEvent.type),callback:function ($$v) {_vm.$set(_vm.reminderEvent, "type", $$v)},expression:"reminderEvent.type"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required|integer","name":"Day"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Day"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"Day"},model:{value:(_vm.reminderEvent.day),callback:function ($$v) {_vm.$set(_vm.reminderEvent, "day", $$v)},expression:"reminderEvent.day"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required|integer","name":"Month"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Month"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"Month"},model:{value:(_vm.reminderEvent.month),callback:function ($$v) {_vm.$set(_vm.reminderEvent, "month", $$v)},expression:"reminderEvent.month"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[(_vm.reminderEvent.type.val >= 4)?_c('ValidationProvider',{attrs:{"rules":"required|integer","name":"Year"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Year"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"Year"},model:{value:(_vm.reminderEvent.year),callback:function ($$v) {_vm.$set(_vm.reminderEvent, "year", $$v)},expression:"reminderEvent.year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3216085123)}):_vm._e()],1),_c('b-col',{attrs:{"md":"12"}},[(_vm.eventDate)?_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(" Week date: "+_vm._s(_vm.$commonFunc.weekDays(_vm.eventDate))+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Left date: "+_vm._s(_vm.$commonFunc.diffDateForHuman(_vm.eventDate))+" ")])]):_vm._e()]),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.createReminderEventAction}},[_vm._v(" Create ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }