<template>
  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <ValidationObserver ref="createReminderEventValidation">
      <b-card-code>
        <h3 class="mb-2">
          Create reminder events
        </h3>
        <b-row>
          <b-col md="3">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Name"
            >
              <b-form-group label="Name">
                <b-form-input
                  v-model="reminderEvent.name"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="3">
            <b-form-group label="English name">
              <b-form-input
                v-model="reminderEvent.en_name"
                placeholder="English name"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Is Solar">
              <v-select
                v-model="reminderEvent.is_solar"
                :options="isSolar"
                :clearable="false"
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Type">
              <v-select
                v-model="reminderEvent.type"
                :options="types"
                :clearable="false"
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required|integer"
              name="Day"
            >
              <b-form-group label="Day">
                <b-form-input
                  v-model="reminderEvent.day"
                  type="number"
                  placeholder="Day"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="4">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required|integer"
              name="Month"
            >
              <b-form-group label="Month">
                <b-form-input
                  v-model="reminderEvent.month"
                  type="number"
                  placeholder="Month"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="4">
            <ValidationProvider
              v-if="reminderEvent.type.val >= 4"
              v-slot="{ errors }"
              rules="required|integer"
              name="Year"
            >
              <b-form-group label="Year">
                <b-form-input
                  v-model="reminderEvent.year"
                  type="number"
                  placeholder="Year"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="12">
            <div v-if="eventDate">
              <p class="mb-0">
                Week date: {{ $commonFunc.weekDays(eventDate) }}
              </p>
              <p class="mb-0">
                Left date: {{ $commonFunc.diffDateForHuman(eventDate) }}
              </p>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="createReminderEventAction"
            >
              Create
            </b-button>
          </b-col>
        </b-row>
      </b-card-code>
    </ValidationObserver>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
// import { required } from '@validations'

const { mapGetters, mapActions } = createNamespacedHelpers('reminderEvent')

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mixins: [toastification],
  data() {
    return {
      isSolar: [
        { name: 'Solar', val: 1 },
        { name: 'Lunar', val: 0 },
      ],
      types: [
        { name: 'Global events', val: 1 },
        { name: 'Year events', val: 2 },
        { name: 'Dynamic events', val: 3 },
        { name: 'Anniversary events', val: 4 },
        { name: 'Personal events', val: 5 },
      ],
      reminderEvent: {
        name: '',
        en_name: '',
        day: null,
        month: null,
        year: null,
        is_solar: null,
        type: null,
      },
    }
  },
  computed: {
    eventDate() {
      return this.$commonFunc.createReminderEvent(
        this.reminderEvent.year,
        this.reminderEvent.month,
        this.reminderEvent.day,
        this.reminderEvent.type?.val,
      )
    },
    ...mapGetters([
      'message',
      'status',
      'loading',
    ]),
  },
  async created() {
    this.initParams()
  },

  methods: {
    ...mapActions(['createReminderEvents']),

    initParams() {
      // eslint-disable-next-line prefer-destructuring
      this.reminderEvent.type = this.types[4]
      // eslint-disable-next-line prefer-destructuring
      this.reminderEvent.is_solar = this.isSolar[0]

      const today = new Date()
      this.reminderEvent.day = today.getDate()
      this.reminderEvent.month = today.getMonth() + 1
      this.reminderEvent.year = today.getFullYear()
    },

    async createReminderEventAction() {
      const success = await this.$refs.createReminderEventValidation.validate()
      if (!success) {
        this.toastFailure('Validation failed')
        return
      }
      const params = { ...this.reminderEvent }
      params.type = params.type.val
      params.is_solar = params.is_solar.val

      await this.createReminderEvents(params)
      if (this.status) {
        this.toastSuccess('Create reminder event successfully')
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>
